import React, { lazy, Suspense } from 'react'

const PortfolioHierarchicalListLazy = lazy(() => import('./components/PortfoliosList/PortfolioHierarchicalList'))

export const PortfolioHierarchicalList = (props) => {
  return (
    <Suspense fallback="Loading...">
      <PortfolioHierarchicalListLazy {...props} />
    </Suspense>
  )
}
